/* bootstrap overwrite */
/* bootstrap overwrite */
.slider-actions slick-carousel-counter {
  --current-slide-color: #001f47;
  --separator-color: #546983;
  --slide-count-color: #546983;
}

.bg-dark .slider-actions slick-carousel-counter {
  --current-slide-color: #fff;
  --separator-color: #e4eaf7;
  --slide-count-color: #e4eaf7;
}

.slider-area .text-section {
  margin-bottom: 24px;
}
.slider-area .slick-slider[align-items=start] .slick-track .slick-slide {
  align-self: flex-start;
}
.slider-area .slick-slider[align-items=center] .slick-track .slick-slide {
  align-self: center;
}
.slider-area .slick-slider[align-items=end] .slick-track .slick-slide {
  align-self: flex-end;
}
.slider-area .slick-slider .slick-list {
  padding-bottom: 0 !important;
  border-bottom: none;
}