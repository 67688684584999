@import 'webresources-sources/scss/imports';
@import '../../../includes/components/slick-carousel-counter';

.slider-area {
  .text-section {
    margin-bottom: 24px;
  }

  .slick-slider {
    &[align-items='start'] {
      .slick-track {
        .slick-slide {
          align-self: flex-start;
        }
      }
    }

    &[align-items='center'] {
      .slick-track {
        .slick-slide {
          align-self: center;
        }
      }
    }

    &[align-items='end'] {
      .slick-track {
        .slick-slide {
          align-self: flex-end;
        }
      }
    }

    .slick-list {
      padding-bottom: 0 !important;
      border-bottom: none;
    }
  }
}
